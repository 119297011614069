import { useSelector } from 'react-redux';

function useAuthorization() {
  const { roles } = useSelector((state) => state).user;

  const hasAnyRole = (inputRoles) => {
    if (roles.length == 0) {
      return false;
    }
    for (let i = 0; i < roles.length; i++) {
      for (let j = 0; j < roles.length; j++) {
        if (inputRoles[i] === roles[j].role) {
          return true;
        }
      }
    }
    return false;
  };

  return { hasAnyRole };
}

export default useAuthorization;
