import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import { Map } from './components';

import Container from 'common/Container';
import { Headline, Team } from './components';

// eslint-disable-next-line react/prop-types
const About = ({ themeMode = 'light' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <Box>
      <Container>
        <Container paddingTop={'0 !important'}>
          <Team />
        </Container>
        <Grid container spacing={isMd ? 8 : 4}>
          <Grid item container xs={12} md={6} alignItems={'center'}>
            <Container>
              <Headline />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Map themeMode={themeMode} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
