import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
// import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
// import AnchorIcon from '@mui/icons-material/Anchor';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';

const Highlights = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={2}>
        {[
          {
            title: 'Fiber/Coax Line placement',
            subtitle:
              'Our team of professional cable placers will get the plant up to spec quickly in a safely manner.',
            icon: <NetworkPingIcon />,
          },
          {
            title: 'Fiber/Coax splicing',
            subtitle:
              'We are working in a team dedicated to deploy the job and demonstrate the quality with enhanced testing.',
            icon: <ElectricalServicesIcon />,
          },

          // {
          //   title: 'Pole installation/transfers',
          //   subtitle:
          //     'We have skills and resourses to build the plant from the ground up. Literally.',
          //   icon: <AlignVerticalCenterIcon />,
          // },
          // {
          //   title: 'Anchor set up',
          //   subtitle:
          //     'Expertly securing anchors to the ground for the pole robustness',
          //   icon: <AnchorIcon />,
          // },
          {
            title: 'Underground construction',
            subtitle:
              'Able to work in flexible aerial to underground environments to keep moving forward with the task.',
            icon: <FormatUnderlinedIcon />,
          },
          {
            title: 'Emergency work',
            subtitle:
              'Available to support our customers during unforeseen situations.',
            icon: <TaxiAlertIcon />,
          },
        ].map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={'100%'} data-aos={'fade-up'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="textSecondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Highlights;
