import React from 'react';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const Story = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Our motivation
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          With focus on speed and quality
          <br />
          we want to be a reliable partner for you
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
        >
          We are a team of creative consultants who help bridge the digital gap
          between companies and their clients with websites that not only serve
          as marketing platforms but also provide solutions to online business
          problems and digital marketing strategies that connect you with the
          ideal client and help create a loyal customer.
        </Typography>
      </Box>
    </Box>
  );
};

export default Story;
