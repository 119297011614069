import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { ManageProjectsTable } from './components';
import { useSelector } from 'react-redux';
import { projectsApi } from 'api';

const Projects = () => {
  const { token } = useSelector((state) => state).user;
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsResponse = await projectsApi.getAllProjects(token);
        setProjects(projectsResponse.data);
      } catch (error) {
        alert(
          'There has been an issue loading projects, please try again later!',
        );
      }
    };

    fetchProjects();
  }, []);

  return (
    <Box marginY={4}>
      <Container>
        <ManageProjectsTable projects={projects} />
      </Container>
    </Box>
  );
};

export default Projects;
