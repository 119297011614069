import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOG_OUT, LOG_IN } from 'store/actions';

import {
  removeTokenLS,
  setTokenLS,
  decode,
  isTokenExpired,
  getTokenLS,
} from './authFunctions';
import { userApi } from '../api';

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    removeTokenLS();
    dispatch({ type: LOG_OUT });
    navigate('/');
  };

  const logIn = async (token) => {
    // @TODO add error handling (throw errors that will be handled at implementation)
    // set token in Local Storage
    setTokenLS(token);

    // get username from token
    const username = decode(token).sub;

    // get user information from database
    const response = await userApi.getInitialData(token, username);
    const user = response.data;

    dispatch({ type: LOG_IN, user: { ...user, token } });
  };

  const isTokenValid = () => {
    if (getTokenLS()) {
      // check if token is not expired
      if (isTokenExpired()) {
        return false;
      }
      return true;
    }
    return false;
  };

  return { logOut, logIn, isTokenValid };
}

export default useAuth;
