import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EngexLogo from 'svg/logos/EngexLogo';

const Footer = () => {
  // eslint-disable-next-line no-undef
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            underline="none"
            href="/"
            title="Engex"
            height={24}
            width={120}
          >
            <EngexLogo height={'100%'} width={'100%'} />
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/"
                color="textPrimary"
                variant={'subtitle2'}
              >
                Home
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/about"
                color="textPrimary"
                variant={'subtitle2'}
              >
                About
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/hire-us"
                color="textPrimary"
                variant={'subtitle2'}
              >
                Hire Us
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/login"
                color="textPrimary"
                variant={'subtitle2'}
              >
                Log In
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="textSecondary"
          gutterBottom
        >
          &copy; Engex Technologies Inc. 2024. All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="textSecondary"
          component={'p'}
        >
          When you access our websites, services, or tools, we or our authorized
          service providers may utilize cookies to store information. These
          cookies help us provide you with a more efficient, faster, and safer
          experience, as well as for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
