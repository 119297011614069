function accountNavigationPages(userFullName) {
  return [
    {
      roles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER'],
      title: userFullName,
      id: 'general',
      pages: [
        {
          title: 'Projects',
          href: '/user/projects',
        },
        {
          title: 'Account',
          href: '/user/account',
        },
      ],
    },
    {
      roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      title: 'Manage',
      id: 'manager',
      pages: [
        {
          title: 'Users',
          href: '/manage/users',
        },
        {
          title: 'Projects',
          href: '/manage/projects',
        },
      ],
    },
    {
      title: 'Admin',
      id: 'admin',
      roles: ['ROLE_ADMIN'],
      pages: [
        {
          title: 'General',
          href: '/admin/general',
        },
      ],
    },
  ];
}

export default accountNavigationPages;
