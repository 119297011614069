import { jwtDecode } from 'jwt-decode';
const tokenIdentifier = 'TKN';
export function getTokenLS() {
  return localStorage.getItem(tokenIdentifier);
}

export function setTokenLS(token) {
  localStorage.setItem(tokenIdentifier, token);
}

export function removeTokenLS() {
  localStorage.removeItem(tokenIdentifier);
}

export function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}
export function decode(token) {
  return jwtDecode(token);
}


