import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from 'common/Container';




const Account= () => {
  const { firstName, lastName, email} = useSelector((state) => state).user;

  return (
    <Box>
      <Container>
        <Box>
          <Box>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 100,
              }}
            >
              Account
            </Typography>
            <Typography gutterBottom>
              Full Name: {firstName} {lastName}
            </Typography>
            <Typography gutterBottom>
              Email: {email}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Account.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Account;
