import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uploadedFilesApi } from 'api';
import Modal from 'common/Modal.js';
import { AddMapForm } from '.';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: 'auto',
  maxWidth: '500px',
  boxShadow: 24,
  p: 2,
};

const AddMap = ({ availableUsers }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box marginY={4}>
      <Modal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal}>
        <Box sx={style}>
          <Button variant="contained" onClick={handleCloseModal}>
            CLOSE
          </Button>
          <AddMapForm availableUsers={availableUsers} />
        </Box>
      </Modal>
      <Button variant="contained" onClick={handleOpenModal}>
        Add Map
      </Button>
    </Box>
  );
};

export default AddMap;
