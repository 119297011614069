/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from 'common/Container';
import Typography from '@mui/material/Typography';
import { BiPattern, Reviews } from './components';
import { useParams } from 'react-router-dom';
import { colors } from '@mui/material';

const Hiring = () => {
  const { id } = useParams();
  const partnerApplyUrl = 'https://forms.office.com/r/zDi30VuUC0';
  const employeeApplyUrl = 'https://forms.office.com/r/pwNzDUZBER';
  const construction = {
    aerial: {
      title: 'Telecom Aerial Construction',
      id: 0,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Reading maps',
        'Environment and regulatory factors evaluation',
        'Logistics and labor planning',
        'Safe performance',
        'Consistent reporting',
        'Weather and difficulty tolerance',
        'Fiber, coax, and copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/construction/aerial/1.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/aerial/2.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/aerial/3.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/aerial/4.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
    underground: {
      title: 'Telecom Underground Construction',
      id: 1,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Reading maps',
        'Environment and regulatory factors evaluation',
        'Logistics and labor planning',
        'Safe performance',
        'Consistent reporting',
        'Weather and difficulty tolerance',
        'Fiber, coax, and copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/construction/underground/1.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/underground/2.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
    splicing: {
      title: 'Telecom Splicing',
      id: 2,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Reading maps',
        'Environment and regulatory factors evaluation',
        'Logistics and labor planning',
        'Safe performance',
        'Consistent reporting',
        'Weather and difficulty tolerance',
        'Fiber, coax, and copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/construction/splicing/1.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/splicing/2.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/construction/splicing/3.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
    anchors: {
      title: 'Telecom Anchor Installation',
      id: 3,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Reading maps',
        'Environment and regulatory factors evaluation',
        'Logistics and labor planning',
        'Safe performance',
        'Consistent reporting',
        'Weather and difficulty tolerance',
        'Fiber, coax, and copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/construction/anchors/1.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
    poles: {
      title: 'Telecom Poles Installation and Removal',
      id: 4,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Reading maps',
        'Environment and regulatory factors evaluation',
        'Logistics and labor planning',
        'Safe performance',
        'Consistent reporting',
        'Weather and difficulty tolerance',
        'Fiber, coax, and copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/construction/poles/1.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
  };

  useEffect(() => {
    // Scroll to the element with the specified ID
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [id]);

  return (
    <Box>
      <Container>
        <Box marginBottom={4}>
          <Box
            component={Typography}
            fontWeight={700}
            variant={'h3'}
            gutterBottom
            align={'center'}
          >
            Telecom Construction
          </Box>
          <Typography align={'center'} color="textSecondary" variant={'h6'}>
            Installing, maintaining, and sometimes designing the physical
            <br />
            infrastructure required for telecommunications networks.
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Container id="aerial" backgroundColor={id === 'aerial' ? '#C5E9D6' : ''}>
        <BiPattern specs={construction.aerial} />
      </Container>
      <Divider />
      <Container
        id="underground"
        backgroundColor={id === 'underground' ? '#C5E9D6' : ''}
      >
        <BiPattern specs={construction.underground} />
      </Container>
      <Divider />
      <Container
        id="splicing"
        backgroundColor={id === 'splicing' ? '#C5E9D6' : ''}
      >
        <BiPattern specs={construction.splicing} />
      </Container>
      <Divider />
      <Container
        id="anchors"
        backgroundColor={id === 'anchors' ? '#C5E9D6' : ''}
      >
        <BiPattern specs={construction.anchors} />
      </Container>
      <Divider />
      <Container id="poles" backgroundColor={id === 'poles' ? '#C5E9D6' : ''}>
        <BiPattern specs={construction.poles} />
      </Container>
      <Divider />
      <Container id="hiring" backgroundColor={id === 'hiring' ? '#C5E9D6' : ''}>
        <Reviews employeeApplyLink={employeeApplyUrl} />
      </Container>
    </Box>
  );
};

export default Hiring;
