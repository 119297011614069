import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthorization, useAuthentication } from 'auth';

const SidebarNav = ({ pages, onClose }) => {
  const theme = useTheme();
  const { hasAnyRole } = useAuthorization();
  const [activeLink, setActiveLink] = useState('');
  const { logOut } = useAuthentication();
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  return (
    <Box>
      <Box
        justifyContent={'flex-end'}
        onClick={() => onClose()}
        sx={{ display: { md: 'none', sm: 'flex' } }}
      >
        <CloseIcon fontSize="small" />
      </Box>
      <Box>
        {pages.map((item, i) =>
          hasAnyRole(item.roles) ? (
            <Box key={i} marginBottom={3}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  marginBottom: 1,
                  display: 'block',
                }}
              >
                {item.title}
              </Typography>
              <Box>
                {item.pages.map((p, i) => (
                  <Box marginBottom={1 / 2} key={i}>
                    <Button
                      component={'a'}
                      href={p.href}
                      target={p.target}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        color:
                          activeLink === p.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === p.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === p.href ? 600 : 400,
                      }}
                      onClick={() => onClose()}
                    >
                      {p.title}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            ''
          ),
        )}
      </Box>
      <Box>
        <Button variant="outlined" fullWidth component="a" href="/">
          Browse pages
        </Button>
      </Box>
      <Box marginTop={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          onClick={logOut}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
