import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EngexLogo from 'svg/logos/EngexLogo';
import { useAuthentication } from 'auth';

const Footer = () => {
  const { logOut } = useAuthentication();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            underline="none"
            href="/"
            title="webbee"
            height={24}
            width={35}
          >
            <EngexLogo height={'100%'} width={'100%'} />
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/"
                color="textPrimary"
                variant={'subtitle2'}
              >
                Home
              </Link>
            </Box>
            <Box marginTop={1}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                onClick={logOut}
                size="small"
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="textSecondary"
          gutterBottom
        >
          &copy; 2024 Engex Tech. All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="textSecondary"
          component={'p'}
        >
          The access to this website and to any attachments are confidential and
          intended solely for the use of the individual or entity to whom they
          are addressed. If you have accessed this account in error, please
          notify contact@engextech.com immediately and delete credentials from
          your system. Any unauthorized use, distribution, or disclosure of the
          information contained in this communication is strictly prohibited.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
