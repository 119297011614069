import React from 'react';
import { IoImageOutline } from 'react-icons/io5';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BsFiletypeTxt } from 'react-icons/bs';
import { SiMicrosoftexcel } from 'react-icons/si';
import { SiMicrosoftword } from 'react-icons/si';
import { MdOutlineGifBox } from 'react-icons/md';
import { ImFilePdf } from 'react-icons/im';

const FileTypeIcon = ({ mimeType }) => {
  switch (mimeType) {
    case 'image/png':
      return <IoImageOutline size="28px" />;
    case 'image/jpeg':
      return <IoImageOutline size="28px" />;
    case 'image/heic':
      return <IoImageOutline size="28px" />;
    case 'image/avif':
      return <IoImageOutline size="28px" />;
    case 'image/webp':
      return <IoImageOutline size="28px" />;
    case 'image/gif':
      return <MdOutlineGifBox size="28px" />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <SiMicrosoftexcel size="28px" />;
    case 'application/pdf':
      return <ImFilePdf size="28px" />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <SiMicrosoftword size="28px" />;
    case 'text/plain':
      return <BsFiletypeTxt size="28px" />;
    default:
      return <PictureAsPdfIcon />;
  }
};

export default FileTypeIcon;
