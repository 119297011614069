import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContactUs from 'common/ContactUs';

const Headline = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: '30%',
          zIndex: 1,
          top: 0,
          left: 0,
          height: '100%',
          backgroundSize: '18px 18px',
          opacity: 0.2,
        },
      }}
    >
      <Box position="relative" zIndex={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Why we do this
        </Typography>
        <Box marginBottom={2}>
          <Typography
            variant="h3"
            align={'center'}
            sx={{
              fontWeight: 700,
            }}
          >
            Enabling success through responsibility and consistency
          </Typography>
        </Box>
        <Box marginBottom={4}>
          <Typography variant="h6" align={'center'} color={'textSecondary'}>
            Engex Technologies, established by Pavel and Yaroslav, draws upon
            the founders&apos; extensive industry experience to offer expert
            support to our esteemed telecom partners using newest technologies
            and most efficient operational algorithms.
          </Typography>
        </Box>
        <Box display="flex" justifyContent={'center'}>
          <ContactUs name={'Contact Us'}></ContactUs>
        </Box>
      </Box>
    </Box>
  );
};

export default Headline;
