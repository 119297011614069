/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Galery } from '../index';

const Overview = ({ themeMode = 'light', specs }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={isMd ? 8 : 4}>
        <Grid item xs={12} key={specs.id}>
          <Grid
            container
            spacing={isMd ? 4 : 2}
            flexDirection={specs.id % 2 === 1 ? 'row-reverse' : 'row'}
          >
            <Grid
              item
              xs={12}
              md={6}
              data-aos={isMd ? 'fade-right' : 'fade-up'}
            >
              <Box
                component={Card}
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                height={'100%'}
                bgcolor={specs.color[themeMode === 'light' ? 50 : 900]}
                boxShadow={0}
              >
                <CardContent>
                  <Galery photos={specs.photos} />
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                  >
                    {specs.title}
                  </Box>
                  <Typography color="text.secondary">
                    Please apply using the links below if
                    <br />
                    <Typography
                      component="a"
                      href={specs.employeeApply}
                      color={theme.palette.text.primary}
                      fontWeight={'700'}
                      target="_blank"
                    >
                      seeking work
                    </Typography>{' '}
                    or{' '}
                    <Typography
                      component="a"
                      href={specs.partnerApply}
                      color={theme.palette.text.primary}
                      fontWeight={'700'}
                      target="_blank"
                    >
                      require additional crews to perform work
                    </Typography>
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid
              item={specs ? true : false}
              xs={12}
              md={6}
              data-aos={isMd ? 'fade-left' : 'fade-up'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                width={'100%'}
                height={'100%'}
              >
                <Box marginBottom={2}>
                  <Box
                    component={Typography}
                    variant={'h4'}
                    gutterBottom
                    fontWeight={600}
                  >
                    {specs.title}
                  </Box>
                  <Typography color="text.secondary">
                    {specs.subtitleTwo}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {specs.lessons.map((lesson, j) => (
                    <Grid item xs={12} key={j}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={lesson} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

Overview.propTypes = {
  themeMode: PropTypes.string,
  specs: PropTypes.any,
};

export default Overview;
