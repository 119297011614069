import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mapsApi } from 'api';

const validationSchema = Yup.object({
  name: Yup.string('Enter map name').required('Map name is required'),
  location: Yup.string('Enter map location').required(
    'Map location is required',
  ),
  notes: Yup.string('Enter notes'),
  assignedUsers: Yup.array().min(1, 'At least one user must be assigned'),
});

const Form = ({ availableUsers, mapInfo }) => {
  const { token } = useSelector((state) => state).user;
  const { projectId } = useParams();
  const theme = useTheme();
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    // set assignedUsers
  }, []);

  function handleCheckChange(username) {
    setAssignedUsers((prevAssignedUsers) => {
      const isAlreadyAssigned = prevAssignedUsers.includes(username);

      if (isAlreadyAssigned) {
        return prevAssignedUsers.filter((user) => user !== username);
      } else {
        return [...prevAssignedUsers, username];
      }
    });
    formik.setFieldValue('assignedUsers', assignedUsers);
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      notes: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const mapData = {
        ...values,
        projectId: projectId,
        assignedUsers,
      };

      mapsApi
        .saveMap(token, mapData)
        .then((response) => {
          alert('Data been saved, please refresh the page');
        })
        .catch((error) => {
          alert('There been an issue saving the map, please try again later!');
        });
    },
  });

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    /* ...rest of your form code here... */
    <Box>
      <Box
        padding={{ xs: 3, sm: 6 }}
        width={'100%'}
        component={Card}
        borderRadius={2}
        boxShadow={4}
        marginBottom={4}
      >
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Map Name"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Map Location"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Notes"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                id="notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                {availableUsers.map((user, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={user.isChecked}
                        onChange={() => handleCheckChange(user.username)}
                      />
                    }
                    label={user.firstName + ' ' + user.lastName[0]}
                  />
                ))}
              </FormGroup>
              {formik.touched.assignedUsers && formik.errors.assignedUsers && (
                <div>{formik.errors.assignedUsers}</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ height: 54 }}
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Typography color="text.secondary" align={'center'}>
          We'll get back to you in 1-2 business days.
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
