import axios from 'axios';

const api = {
  getAllProjects: function (token) {
    return axios.get('/api/projects', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  getProjectsByUsername: function (token) {
    return axios.get('/api/projects/username', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
