import axios from 'axios';

const api = {
  uploadFiles: function (token, formData) {
    return axios.post('/api/uploaded_files', formData, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFilesByUserAndProjectAndMap: function (token, projectId, mapId) {
    return axios.get(
      '/api/uploaded_files/project/' + projectId + '/map/' + mapId,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  getFilesByProjectAndMap: function (token, projectId, mapId) {
    return axios.get(
      '/api/uploaded_files/project/' +
        projectId +
        '/map/' +
        mapId +
        '/not_by_user',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
};

export default api;
