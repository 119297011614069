/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContactUs from 'common/ContactUs';

const Footer = () => {
  return (
    <Box>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'textSecondary'}
        align={'center'}
      >
        Get Started
      </Typography>
      <Box
        component={Typography}
        fontWeight={700}
        variant={'h3'}
        gutterBottom
        align={'center'}
      >
        Get started with Engex Tech today
      </Box>
      <Typography
        variant={'h6'}
        component={'p'}
        color={'textSecondary'}
        align={'center'}
      >
        We are a dependable and trustworthy partner for you to confidently rely
        on.
      </Typography>
      <Box marginTop={3} display={'flex'} justifyContent={'center'}>
        <ContactUs name={'Contact Us'}></ContactUs>
      </Box>
    </Box>
  );
};

export default Footer;
