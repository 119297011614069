/* eslint-disable no-undef */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

const Reviews = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'left'}
          gutterBottom
        >
          Reviews by people that
          <br />
          works with us.
        </Box>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            feedback:
              // eslint-disable-next-line quotes
              "Accidentally stumbled upon the opportunity to participate in a project for installing fiber optic lines. The folks from Engextech quickly and efficiently got me up to speed and provided me with everything necessary for productive work. The job isn't the easiest, nor the cleanest. It requires following safety rules and executing necessary tasks precisely to avoid having to redo anything. But it's worth it. In two weeks of working with them, I gained interesting experience, positive emotions, and earned good money. Now I'm looking forward to an invitation from them for the next project. Dealing with the Engextech team was pleasant both during work moments and regular human interactions. 🤝",
            name: 'Igor I',
            title: 'Telecommunication Lineman',
            avatar: require('../../../../../assets/images/hiring/igor_i.jpeg'),
          },
          {
            feedback:
              // eslint-disable-next-line quotes
              'Привет. Я работал в компании Engex Tech, ребята умные и сообразительные. Хорошо знают как нужно работать, у них есть весь инструмент и знание как его применить. Если вы готовы к тяжёлой работе и хотите зарабатывать хорошие деньги, вам суда. В этой компании работают честные люди, своих не обманывают.',
            name: 'Sergey S',
            title: 'Telecommunication Helper/Lineman',
            avatar: require('../../../../../assets/images/hiring/sergey_s.jpeg'),
          },
          {
            feedback:
              // eslint-disable-next-line quotes
              'I worked for this company for about six months. I especially remember the attitude: they are always ready to help, they don’t give up in difficult situations, this is important in this work. In addition, they are very careful with payments, counting and paying everything down to the cent. The downside is that there is not 100% occupancy per month, there is not always work. But the guys are already working on it. I think there will be more and more work. I can definitely recommend it, good company.',
            name: 'Vadim K',
            title: 'Telecommunication Helper/Lineman',
            avatar: require('../../../../../assets/images/hiring/vadim_k.jpeg'),
          },
        ].map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={'100%'}
              height={'100%'}
              data-aos={'fade-up'}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              boxShadow={0}
            >
              <Box
                component={CardContent}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
              >
                <Box marginBottom={1} display={'flex'}>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <Box key={item} color={theme.palette.secondary.main}>
                      <svg
                        width={18}
                        height={18}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </Box>
                  ))}
                </Box>
                <Typography align={'left'} color="textSecondary">
                  {item.feedback}
                </Typography>
              </Box>
              <Box flexGrow={1} />
              <CardActions>
                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  <ListItemAvatar>
                    <Avatar src={item.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={item.name}
                    secondary={item.title}
                  />
                </ListItem>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
