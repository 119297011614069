import axios from 'axios';

const api = {
  getAllMaps: function (token) {
    return axios.get('/api/maps', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  saveMap: function (token, mapData) {
    return axios.post('/api/maps', mapData, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  getMapsByUsernameAndProjectId: function (token, projectId) {
    return axios.get('/api/maps/project/' + projectId, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
