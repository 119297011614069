import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useAuthentication } from 'auth';

const LoggedInBar = () => {
  const { logOut } = useAuthentication();
  return (
    <Box
      display={'flex'}
      justifyContent={'space-around'}
      alignItems={'center'}
      width={'100%'}
      backgroundColor={'purple'}
      marginBottom={1}
      paddingY={1}
    >
      <Box>
        <Typography variant="body1" color="secondary">
          Your are Logged In
        </Typography>
      </Box>
      <Box display="flex" alignItems={'center'}>
        <Box sx={{ display: { xs: 'flex' } }} alignItems={'center'}>
          <Box marginX={2}>
            <Link
              underline="none"
              component="a"
              href="/user/projects"
              color="secondary"
            >
              Go Back to Dashboard
            </Link>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              component="a"
              onClick={logOut}
              size="small"
            >
              Log Out
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoggedInBar;
