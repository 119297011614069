import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { MapsTable } from './components';
import { useParams } from 'react-router-dom';
import { mapsApi } from 'api';
import { useSelector } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Maps = () => {
  const { token } = useSelector((state) => state).user;
  const [maps, setMaps] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    mapsApi
      .getMapsByUsernameAndProjectId(token, projectId)
      .then((response) => {
        setMaps((currentState) => [...response.data, ...currentState]);
        console.log(response.data);
      })
      .catch((error) => {
        alert('There been an issue loading maps, please try again!');
      });
  }, []);
  return (
    <Box marginY={4}>
      <Container>
        <Box marginBottom={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/user/projects">
              Projects
            </Link>
            <Link underline="hover" color="inherit" href="#">
              Maps
            </Link>
          </Breadcrumbs>
        </Box>
        <MapsTable maps={maps} />
      </Container>
    </Box>
  );
};

export default Maps;
