/* eslint-disable no-undef */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';

const Team = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          Hiring
        </Box>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            name: 'Aerial Crews',
            title:
              'Should have the equipment to perform aerial fiber construction tasks',
            avatar: require('../../../../../assets/images/hiring/aerial.jpeg'),
          },
          {
            name: 'Underground Crews',
            title:
              'Should have the equipment to perform underground fiber construction tasks',
            avatar: require('../../../../../assets/images/hiring/underground.jpeg'),
          },
          {
            name: 'Splicing Crews',
            title: 'Should have the equipment to perform splicing tasks',
            avatar: require('../../../../../assets/images/hiring/splicing.jpeg'),
          },
          {
            name: 'Anchor Crews',
            title:
              'Should have the equipment to set up anchors and secure poles',
            avatar: require('../../../../../assets/images/hiring/anchor.png'),
          },
          {
            name: 'Pole Installation Crews',
            title: 'Should have the equipment to set up and remove poles',
            avatar: require('../../../../../assets/images/hiring/poles.jpeg'),
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box component={Card} boxShadow={0} bgcolor={'transparent'}>
              <Box
                component={CardMedia}
                borderRadius={2}
                width={'100%'}
                height={'100%'}
                minHeight={320}
                image={item.avatar}
              />
              <Box
                component={CardContent}
                bgcolor={'transparent'}
                marginTop={-5}
              >
                <Box component={Card} borderRadius={2}>
                  <CardContent>
                    <ListItemText primary={item.name} secondary={item.title} />
                  </CardContent>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
