import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { ManageMapsTable, AddMap } from './components';
import { useSelector } from 'react-redux';
import { mapsApi, userApi } from 'api';

const Maps = () => {
  // TODO need to request all the maps not just ones related to user logged in.
  const { token } = useSelector((state) => state).user;
  const [availableUsers, setAvailableUsers] = useState([]);
  const [projectMaps, setProjectMaps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await userApi.getAllUsers(token);
        setAvailableUsers(usersResponse.data);

        const mapsResponse = await mapsApi.getAllMaps(token);
        setProjectMaps(mapsResponse.data);
      } catch (error) {
        alert(
          'There has been an issue loading all the maps, please try again later!',
        );
      }
    };

    fetchData();
  }, []);
  return (
    <Box marginY={4}>
      <Container>
        <AddMap availableUsers={availableUsers} />
        <ManageMapsTable maps={projectMaps} />
      </Container>
    </Box>
  );
};

export default Maps;
