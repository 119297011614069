import axios from 'axios';

const api = {
  login: function (email, password) {
    return axios.post('/api/users/login', { username: email, email, password });
  },
  getInitialData: function (token, username) {
    return axios.get('/api/users/' + username, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  getAllUsers: function (token) {
    return axios.get('/api/users', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
