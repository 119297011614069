import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const EngexLogo = ({ width = 45, height = 70 }) => {
  const theme = useTheme();
  const colorPrimaryMain = theme.palette.primary.main;
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 360.000000 60.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
        fill={colorPrimaryMain}
        stroke="none"
      >
        <path
          d="M116 538 c-19 -84 -96 -485 -96 -495 0 -10 57 -13 250 -13 205 0 250
2 250 14 0 7 3 28 6 45 l6 31 -157 0 -157 0 6 33 c4 17 9 49 13 70 l6 37 119
0 c86 0 120 3 122 13 3 6 7 24 10 40 l6 27 -120 0 c-120 0 -120 0 -115 23 3
12 9 40 13 62 l8 40 157 3 c86 1 157 6 157 10 0 4 4 24 9 45 l9 37 -249 0
-249 0 -4 -22z"
        />
        <path
          d="M665 308 c-26 -139 -50 -259 -53 -265 -3 -10 16 -13 76 -13 55 0 81
4 85 13 2 6 17 82 32 167 16 85 31 158 34 161 3 4 71 -67 151 -156 87 -98 157
-168 174 -174 16 -6 51 -11 77 -11 42 0 49 3 54 23 13 60 95 485 95 495 0 8
-24 12 -78 12 l-78 0 -23 -122 c-37 -195 -42 -219 -50 -216 -4 2 -76 78 -160
171 l-152 167 -68 0 -67 0 -49 -252z"
        />
        <path
          d="M1715 541 c-129 -38 -229 -113 -261 -198 -25 -64 -15 -143 23 -191
42 -52 86 -79 166 -104 136 -41 438 -5 452 55 14 61 35 170 35 183 0 11 -24
14 -135 14 l-135 0 -6 -25 c-11 -43 1 -55 52 -55 l46 0 -7 -42 c-4 -24 -12
-46 -17 -49 -21 -14 -120 -19 -165 -9 -95 22 -133 64 -133 149 0 62 27 120 70
151 73 52 204 65 305 30 75 -26 74 -26 125 11 l44 32 -40 20 c-67 34 -134 47
-249 46 -74 0 -130 -6 -170 -18z"
        />
        <path
          d="M2309 558 c0 -2 -24 -121 -53 -266 l-53 -262 248 0 248 0 10 38 c6
20 11 40 11 45 0 4 -70 7 -155 7 -122 0 -155 3 -155 13 0 8 5 39 11 70 l12 57
118 0 119 0 6 28 c3 15 7 33 10 40 2 9 -25 12 -117 12 -121 0 -121 0 -116 23
4 12 9 41 13 65 l7 42 157 0 158 0 6 31 c12 63 28 59 -239 59 -135 0 -245 -1
-246 -2z"
        />
        <path
          d="M2886 548 c4 -7 43 -61 86 -120 44 -59 78 -111 76 -116 -1 -5 -70
-69 -153 -142 -82 -73 -152 -135 -155 -137 -3 -3 46 -3 107 -1 l113 3 108 108
109 109 67 -106 c43 -68 76 -108 89 -111 12 -3 57 -5 99 -3 l76 3 -99 140
c-54 77 -99 142 -99 145 1 3 61 57 135 120 l135 115 -102 3 -102 3 -93 -93
c-58 -58 -96 -89 -101 -82 -4 6 -31 48 -61 93 l-54 81 -94 0 c-72 0 -93 -3
-87 -12z"
        />
      </g>
    </svg>
  );
};

EngexLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default EngexLogo;
