import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Container, Typography } from '@mui/material';
import { FilesTable, InputFileUpload } from './components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uploadedFilesApi } from 'api';
import Modal from 'common/Modal.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: 'auto',
  maxWidth: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const Map = () => {
  const { token } = useSelector((state) => state).user;
  const { projectId, mapId } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState({
    fileUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROuYGb-6g_jBi4jHqEHWhAleLzpcX4G5el9A&s',
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    uploadedFilesApi
      .getFilesByProjectAndMap(token, projectId, mapId)
      .then((response) => {
        setUploadedFiles((currentState) => [...response.data, ...currentState]);
      })
      .catch((error) => {
        console.error('Error while fetching uploaded files', error);
      });
  }, []);

  return (
    <Box marginY={4}>
      <Modal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal}>
        <Box sx={style}>
          <Button variant="contained" onClick={handleCloseModal}>
            CLOSE
          </Button>
          <Typography variant="body" component="p">
            <strong>Project:</strong> {file?.projectName}
          </Typography>
          <Typography variant="body" component="p">
            <strong>Map:</strong> {file?.mapName}
          </Typography>
          <Typography variant="body" component="p">
            <strong>File Name:</strong> {file?.uploadedFile?.currentFileName}
          </Typography>
          <Typography variant="body" component="p">
            <strong>Uploaded:</strong> {file?.uploadedFile?.createdAt}
          </Typography>
          <Typography variant="body" component="p">
            <strong>Uploaded By:</strong> {file?.userFullName}
          </Typography>
          <img
            src={file?.uploadedFile?.url}
            style={{
              width: '100%',
              height: 'auto',
            }}
            alt="Uploaded Image"
          />
        </Box>
      </Modal>
      <Container>
        <Box marginBottom={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/user/projects">
              Projects
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={'/user/projects/' + projectId + '/maps'}
            >
              Maps
            </Link>
            <Link underline="hover" color="inherit" href="#">
              Files
            </Link>
          </Breadcrumbs>
        </Box>
        <InputFileUpload
          setUploadedFiles={setUploadedFiles}
          projectId={projectId}
          mapId={mapId}
          token={token}
        />
        <FilesTable
          uploadedFiles={uploadedFiles}
          setFile={setFile}
          handleOpenModal={handleOpenModal}
        />
      </Container>
    </Box>
  );
};

export default Map;
