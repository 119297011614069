import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { ManageUsersTable } from './components';
import { useSelector } from 'react-redux';
import { userApi } from 'api';

const Users = () => {
  const { token } = useSelector((state) => state).user;
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await userApi.getAllUsers(token);
        setUsers(usersResponse.data);
      } catch (error) {
        alert('There has been an issue loading users, please try again later!');
      }
    };

    fetchUsers();
  }, []);

  return (
    <Box marginY={4}>
      <Container>
        <ManageUsersTable users={users} />
      </Container>
    </Box>
  );
};

export default Users;
