/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ContactUs from '../../../../../common/ContactUs';

const Hero = () => {
  const GridItemHeadlineBlock = () => (
    <Box marginBottom={4}>
      <Typography
        variant="h2"
        align={'center'}
        gutterBottom
        sx={{
          fontWeight: 900,
        }}
      >
        Engex Tech Inc
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="textPrimary"
        align={'center'}
        sx={{
          fontWeight: 400,
        }}
      >
        Seeking Passionate Innovators and Skilled Technicians to
        <br /> <b>Shape the Future of Connectivity!</b>
      </Typography>
      <Box
        marginY={4}
        sx={{ justifyContent: 'center', display: 'flex' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
      >
        <ContactUs name={'Contact Us'}></ContactUs>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={'center'}
          >
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
