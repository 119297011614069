import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const Features = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box component={Card} boxShadow={4} data-aos={'fade-up'}>
        <Box component={CardContent} padding={{ sm: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box
                width={'100%'}
                height={'100%'}
                borderRight={{
                  xs: 0,
                  sm: `1px solid ${theme.palette.divider}`,
                }}
                paddingRight={{ xs: 0, sm: 4 }}
                paddingBottom={{ xs: 4, sm: 0 }}
                borderBottom={{
                  xs: `1px solid ${theme.palette.divider}`,
                  sm: 0,
                }}
              >
                <Box
                  component={Typography}
                  fontWeight={600}
                  variant={'h6'}
                  gutterBottom
                >
                  Looking forward to fruitful partnership, please access{' '}
                  <Link
                    fontSize={20}
                    fontWeight={600}
                    target="_blank"
                    href="https://forms.office.com/r/pwNzDUZBER"
                  >
                    First step Partner Onboarding Form
                  </Link>{' '}
                  link and share a bit about your interests and experience.
                </Box>
                <br></br>
                <Typography>Will reach back as soon as we can!</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box marginBottom={4}>
                <Typography fontWeight={600} variant={'h6'} gutterBottom>
                  Empowering Minds, Fostering Excellence: Engex Tech Inc
                  Respects Its Crews.
                </Typography>
                <Typography>Some of the benefits working with us:</Typography>
              </Box>
              <Grid container spacing={1}>
                {[
                  'Competitive Pay',
                  'Timely Pay',
                  'Transparency',
                  'Flexibility',
                ].map((item, i) => (
                  <Grid item xs={12} sm={6} key={i}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width={'auto'}
                      padding={0}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.secondary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={item} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
