import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          Frequently Asked Questions
        </Box>
      </Box>
      <Box>
        {[
          {
            title: 'Why Engex Tech?',
            subtitle:
              'Engex Tech is a team of motivated and result-driven professionals. We are always improving to bring classical values of quality into the quickly evolving technoligical future. You will be able to see benefits working with us whether you are our customer or employee. We offer fast and quality contracting solutions resulting in greater revenues.',
          },
          {
            title: 'What do we do?',
            subtitle:
              'We are a contracting company specialized on construction and maintenance of telecommunications. We do anything ranging from outside plant construction to different size residential and business projects. We are home to cable placers, splicers, installation technicians, drill and auger operators and more.',
          },
          {
            title: 'Where we work?',
            subtitle:
              'We are working on multiple projects nationwide and will do our best to find solutions that work for you.',
          },
          {
            title:
              'I am a professional with my own equipment, can I work with you?',
            subtitle:
              'Yes. We will be happy to approach you with to discuss details.',
          },
          {
            title:
              // eslint-disable-next-line quotes
              "I am a professional and want to work, but I don't have equipment or tools.",
            subtitle:
              'Yes. We are welcoming young professionals and skilled individuals willing to grow in the industry. We also help our employees to transition to a new profession whenever we have an opening.',
          },
          {
            title: 'Do I get paid for training?',
            subtitle:
              'Yes. We provide reasonable pay rate to motivate our employees.',
          },
          {
            title: 'How long is the training?',
            subtitle:
              // eslint-disable-next-line quotes
              "The training depends on your position, skills and willingness to learn. We don't specify a timeframe and monitor each case individually. You will be working with an assigned professional until we can ensure you are safe to operate independently and comply with all industry standards. ",
          },
          {
            title: 'How can we get started?',
            subtitle:
              'Please visit our hiring page for more information and let us know if you have any questions.',
          },
          {
            title: 'Can I bring my cat or dog with me?',
            subtitle: 'Yes, especially if they can pull the lasher.)',
          },
          {
            title: 'What languages Engex Tech Team can communicate?',
            subtitle:
              'English, Russian, Romanian, Ukrainian, and we can figure out Spanish',
          },
        ].map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={2}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
