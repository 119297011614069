import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { uploadedFilesApi } from 'api';
import { useSelector } from 'react-redux';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({ setUploadedFiles }) {
  const { token } = useSelector((state) => state).user;
  const { projectId, mapId } = useParams();

  const handleFileChange = async (event) => {
    const filesArray = Array.from(event.target.files);
    const maxSizeBytes = 10 * 1024 * 1024; // 10 MB

    const schema = yup.array().of(
      yup
        .mixed()
        .test(
          'fileSize',
          'File size is too large',
          (value) => value && value.size <= maxSizeBytes,
        )
        .test(
          'fileType',
          'Unsupported file format',
          (value) =>
            value &&
            [
              'image/png',
              'image/jpeg',
              'image/heic',
              'image/avif',
              'image/webp',
              'image/gif',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'text/plain',
            ].includes(value.type),
        ),
    );

    try {
      const validatedFiles = await schema.validate(filesArray);
      const formData = new FormData();
      for (let i = 0; i < validatedFiles.length; i++) {
        formData.append('files', validatedFiles[i]);
      }

      formData.append('project_id', projectId);
      formData.append('map_id', mapId);

      const response = await uploadedFilesApi.uploadFiles(token, formData);

      setUploadedFiles((currentState) => [...response.data, ...currentState]);

      console.log('Upload successful:', response.data);
    } catch (error) {
      if (error.name === 'ValidationError') {
        console.log(error);
      } else {
        console.error('Error uploading files:', error);
      }
    }
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Upload images or files
      <VisuallyHiddenInput
        type="file"
        multiple
        accept=".png, .jpg, .jpeg, .heic, .avif, .webp, .gif, .xlsx, .pdf, .docx, .txt"
        onChange={handleFileChange}
      />
    </Button>
  );
}
