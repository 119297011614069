import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import { Footer, Hero, Numbers, Main } from './components';

const Home = () => {
  return (
    <Box>
      <Container position="relative">
        <Hero />
      </Container>
      <Container>
        <Numbers />
      </Container>
      <Container>
        <Main />
      </Container>
      <Container>
        <Footer />
      </Container>
    </Box>
  );
};

export default Home;
