import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const EngexLogo = ({ width = 45, height = 70 }) => {
  const theme = useTheme();
  const colorPrimaryMain = theme.palette.primary.main;
  return (
    <svg
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 800 300"
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
.st0{fill:${colorPrimaryMain};}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:${colorPrimaryMain};}`}
      </style>
      <g>
        <path
          className="st0"
          d="M331.7,56.6l-3.2,17.9h-57.4l-3.5,25H306l-2.5,17.9h-38.5l-3.9,27.8h55.2l-2.5,17.7H236l15-106.3L331.7,56.6z"
        />
        <path
          className="st0"
          d="M439.9,56.6L425,162.9h-21.5l-44-72l-10,72H327l15-106.3h22.6l43.1,69.4l9.5-69.4H439.9z"
        />
        <path
          className="st0"
          d="M556.8,59.1l-2.6,19.6c-9.5-2.6-18.9-3.9-28.2-3.9c-15.7,0-28.5,3.8-38.3,11.3c-9.8,7.6-14.8,17.3-14.8,29.3
		c0,9.1,3,16.3,9.2,21.4c6.1,5.1,14.3,7.7,24.6,7.7c5.4,0,10.7-0.7,15.9-2l2.7-18.9h-19.5L508,107h42l-7.6,54.2
		c-8.4,2.1-19.4,3.1-33,3.1c-19.2,0-34-4.5-44.4-13.4c-10.4-8.9-15.6-20.4-15.6-34.5c0-16.8,6.7-31.2,20.2-43.1
		c13.5-12,32.3-18,56.3-18C537.7,55.3,548,56.6,556.8,59.1z"
        />
        <path
          className="st0"
          d="M658.7,56.6l-2.5,17.9h-57.3l-3.5,25h38.5l-2.5,17.9h-38.5l-3.9,27.8h55.2l-2.5,17.7h-77.8l15-106.3H658.7z"
        />
        <path
          className="st0"
          d="M765.5,56.6l-47.1,54.1l31.5,52.2h-24.8l-21.3-35.4l-31.7,35.4h-28.1l48.2-54.5l-31.2-51.8h24.8l21.2,34.9
		l30.4-34.9H765.5z"
        />
        <g>
          <path
            className="st0"
            d="M297.4,181.7l-1.8,12.7h-24.6l-8.9,62.9h-16.1l8.9-62.9h-24l1.8-12.7H297.4z"
          />
          <path
            className="st0"
            d="M364.3,181.7l-1.8,12.7h-40.7l-2.5,17.7h27.3l-1.8,12.7h-27.4l-2.8,19.8H354l-1.8,12.6h-55.3l10.7-75.6H364.3
			z"
          />
          <path
            className="st0"
            d="M433.1,183.5l-2.7,14c-6-1.8-12.1-2.8-18.3-2.8c-10.2,0-18.1,2.9-23.8,8.8c-5.6,5.8-8.5,12.7-8.5,20.6
			c0,6.2,1.9,11.1,5.8,14.7c3.9,3.7,9.1,5.5,15.7,5.5c8.1,0,17.3-2.9,27.5-8.8l-2.3,16.8c-9,4-17.8,5.9-26.6,5.9
			c-11,0-19.8-3.2-26.6-9.6c-6.8-6.4-10.2-14.7-10.2-25c0-12.6,4.6-22.9,13.7-30.9c9.2-8,21-12,35.5-12
			C418.9,180.7,425.8,181.7,433.1,183.5z"
          />
          <path
            className="st0"
            d="M519.2,181.7l-10.6,75.6h-16.1l4.4-30.8h-38.6l-4.3,30.8H438l10.7-75.6h16.1l-4.2,29.9h38.4l4.2-29.9H519.2z"
          />
          <path
            className="st0"
            d="M583.4,181.7l-10.7,75.6h-16.1l10.7-75.6H583.4z"
          />
          <path
            className="st0"
            d="M670.1,181.7l-10.5,75.6h-15.3L613,206.1l-7.1,51.2h-16.1l10.7-75.6h16.1l30.7,49.3l6.8-49.3H670.1z"
          />
          <path
            className="st0"
            d="M747.9,183.5l-2.6,14c-6-1.8-12.1-2.8-18.3-2.8c-10.2,0-18.1,2.9-23.8,8.8c-5.6,5.8-8.5,12.7-8.5,20.6
			c0,6.2,1.9,11.1,5.8,14.7c3.9,3.7,9.1,5.5,15.8,5.5c8.1,0,17.3-2.9,27.5-8.8l-2.3,16.8c-9,4-17.8,5.9-26.6,5.9
			c-11,0-19.8-3.2-26.6-9.6c-6.8-6.4-10.2-14.7-10.2-25c0-12.6,4.6-22.9,13.7-30.9c9.2-8,21-12,35.6-12
			C733.7,180.7,740.5,181.7,747.9,183.5z"
          />
        </g>
        <path
          className="st1"
          d="M212,135.9c-0.3-1.8-1.7-3-3.3-2.8c-0.2,0-0.3,0.1-0.5,0.1c-0.7-0.4-1.6-0.6-2.5-0.5c-0.3,0-0.6,0.1-0.8,0.2
		c-0.5-0.1-1.1-0.2-1.6-0.1c-0.4,0.1-0.7,0.2-1.1,0.3c-0.2,0-0.4-0.1-0.6-0.1l-4.4-29.3l3.6-2c9.4-4.3,7.6-19.5-4.6-18.5l-1.9,0.9
		l-3.1-20.8c1-0.3,1.9-0.9,2.5-1.7c0.8-0.1,1.5-0.5,2.1-1c0.1,0,0.3,0,0.4,0c1.5-0.2,2.7-1.4,3.3-2.9c1.4-0.5,2.2-2.1,1.9-3.9
		c-0.3-2-1.9-3.3-3.6-3.1c-0.2,0-0.4,0.1-0.5,0.1c-0.8-0.5-1.8-0.7-2.7-0.6c-0.3,0.1-0.6,0.1-0.9,0.3c-0.6-0.2-1.2-0.2-1.8-0.1
		c-0.4,0.1-0.8,0.2-1.2,0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.2,0-0.3,0-0.5,0l-1.2-8.2l-4.9-1l-8.5,4.3l1.8,11
		c-9,7.2-13.2,22.8-16.5,32.7c-0.9,2.6-1.8,6.1-2.6,10.8l-14.2,6.2c-4.7-2.3-10.9-3.2-18.3,0.4c-2.9,1.4-7.1,5.4-7.4,11l-3,1.3
		l-4.9-1.9c-1.6-0.5-3.5-0.4-4.9,0.8c-0.8-0.6-1.9,0.1-3.1,1.1c-1.1-0.6-2.6-0.3-3.8,0.8c-1.5-0.5-3.5,0.2-4.5,1.2l-6.9,6.1
		l-13.3,10.1l-29.3,12.9c-17.6,6.4-8,39.8,10.3,34l21.5-12.1c3.8,1.9,7.3,2.9,10.4,3c2.2,4,5.8,4.8,10.9,2.5l8.7,4.1l10.2,24.1
		l5.4,21.9l3.8,0c2.7,8.9,7.7,15.5,14.3,18c11,4.1,24.2-4.3,33.6-19.8c1.5,0.4,2.9,0.6,4.1,0.9c3.2,1,6,1.2,8.2,0.8
		c2,0.5,3.2-0.1,3.6-3c1.9-1.8,3.5-3.8,3.7-7.9c3.2-2.3,2.8-15.2-1.8-20.8c-0.3-4.2-1.6-9.5-4.2-15.7c0-0.2,0-0.4,0-0.7
		c0.9,1.1,1.8,2,3,2.6c7.5,4.2,12.7,2.6,16.3-4.4c1.8-3.5,1.3-13.1,0.4-21.2c-0.6-5.3-1.3-10.3-1.7-13.2c0.2-0.2,0.4-0.4,0.6-0.6
		c0.7-0.1,1.4-0.5,1.9-0.9c0.1,0,0.2,0,0.4,0c1.4-0.2,2.5-1.2,3-2.6C211.5,138.9,212.3,137.5,212,135.9z M202.1,150l-3.5,2.1
		l-6.7-42.8l4-2.4L202.1,150z M187.2,46.1l5.4,37.7l-4.4,1.9l-5.8-37L187.2,46.1z M162,90.4c2.9-8.9,6.9-23.7,14.8-30.9l4.9,30.3
		l-4.9,2.1c-0.8-0.5-1.7-0.8-2.5-0.8c-2.5,0-4.4,2.1-5.7,4.4l-8.9,3.9C160.4,95.5,161.3,92.6,162,90.4z M199.4,86.2
		c1.7-0.3,2.6,0.7,2.7,2.9l-50.2,25.6l-4.7-5.3L199.4,86.2z M180,157.4c0.2,0.8,0.3,1.7,0.5,2.7c-4.2-6.3-10.4-10-17.1-9.4
		c-2.1,0-4.3,0.3-6.5,1l-0.9-0.6c-0.3-1.7-1.6-3.7-4.5-4.5l-0.8-3.2c3.5-2.2,4-5.2,2.3-10.9c0-1.1-0.1-2.3-0.3-3.4l3.5-2
		c0,1.6,0.1,3.2,0.2,4.9c0.2,4.3,1.9,9,4.1,12.3c2.1,3.1,5.4,5.7,8.6,4.1c4.1-2,1.3-18.1,1-20.8c-0.2-1.2-0.7-4.3-1.2-7.7l14.6-8.2
		c0,0.2-0.1,0.4-0.1,0.6C180.5,127.2,177,142.1,180,157.4z M133.2,183.4l-3.3-0.1c9.1-21.2,25.4-34.1,37.8-29.4
		c0.9,0.3,1.7,0.7,2.6,1.2c0.6,0.1,1.1,0.3,1.7,0.5c1,0.4,2,0.9,2.9,1.4c-0.2-0.1-0.4-0.2-0.6-0.2c-12.8-4.8-28.8,8.3-37.4,29.7
		l-2.1-1.8c8.2-19.9,22.8-32.5,35.1-29.8C157.4,150.5,141.9,162.9,133.2,183.4z M130.6,131.8l2.3-0.1c-0.6-1.2-0.6-2.4,0.1-3.5
		l-1-2.3c1.9-0.1,3.6-0.3,5.3-0.4l-0.9,1.4l1.8-0.5c0.7,0.8,2.4,0.9,2.7,1.1c0.8-0.4,1.5-1,2.1-1.8c0.8,0.5,1.3,0.7,1.7,0.4
		l-0.8-1.6c1-0.2,1.8-0.4,2.6-0.6c0,0,0,0,0,0l0.8,2l1.6-0.1l0.6-1.2c2,1.2,2.3,5.7,2.4,8.4c2,6.5,0.1,7.1-3.2,7.5
		c-6.5,0.9-10.7,4.7-16.4-2.6c0.1,3.8,3,6.4,8.6,7.6c2.2,5.1,1.6,9.6-1.9,13.2c-2.5,1.2-4.7,1.1-6.4-0.3c-1.2-6.4-2.7-12.6-4.4-18.5
		c1.6-0.2,2.5-0.7,2.5-1.5C123.8,140.4,122.1,122.4,130.6,131.8z M152.1,149.8c1-0.1,1.7,1,2.3,2.7c-6.2,2.5-12.4,7.7-17.7,14.7
		C140.3,160.4,149.4,155,152.1,149.8z M174.1,157.8c1,0.4,1.9,0.8,2.8,1.4c-0.2-0.1-0.4-0.2-0.5-0.2c-12.5-4.8-28.1,8.6-36.2,30.4
		l-2-1.7C146.3,166.2,161.8,153.1,174.1,157.8z M158.2,125.9l8.7-4.9c1.3,8.1,2.9,19.3,1.9,24c-1,4.7-5.6-0.3-6.6-1.9
		c-2-3-3.6-7.3-3.8-11.2C158.3,129.8,158.2,127.8,158.2,125.9z M149,116.3c1,1.4,8.3,0.1,6.5,3.3c-6.2,0-12.9,3.9-22,4.8
		c-2.3,0.5-3.2-0.8-5.3,0c-3.9,1.4-8.3,6.1-9.6,5.6l1.8-4.2C114.5,107.8,139,99.2,149,116.3z M126.9,143.5l0.2,3
		c-2.3,0.9-3.9,2.1-4.9,3.8l-6-0.9L126.9,143.5z M89.1,127.9l5.1-4.8c1.1-1.2,2.5-1.6,4.4-1.2c0.7-0.8,1.8-1.1,3.3-0.9
		c0.8-0.8,1.7-1.2,2.8-1c0.6-0.7,3.4-1.3,4.5-1.1l5.5,2.8c0.3,3.9,0.5,5.7,0.7,7.3c0.2,1.3-1.6,1.8-2.5,0.3l-0.9-5.9l-4.2-2.2
		c-0.7-0.2-0.9,0-0.8,0.4l3.9,2.3l0.5,6.4c0.1,1.9-2,2.4-2.7,0.5l-0.5-5.5l-4.8-2.6c-0.7-0.4-1.1-0.2-1.1,0.3l4.9,2.9l0.2,6.5
		c-0.6,1-2.3,0.9-2.8-0.7l-0.7-4.8l-4.5-2.6c-0.9-0.5-1.3-0.3-1,0.4l4.2,3v5.9c-1.2,0.6-2.5,0.4-2.8-0.6c0.1-0.7-0.1-1.9-0.3-3.7
		c0-0.1-3.5-2.6-3.5-2.6l-13.6,5.9L89.1,127.9z M57.8,161.7c-0.6-4.1-3.4-7.3-8.2-9.6l45.6-19.7c0.1,6.3,9,3.4,8.3,6.9L57.8,161.7z
		 M86.8,166.8l4-1.9c-1.4,5.7,0,10,3.9,13C88.7,178,86,174.3,86.8,166.8z M118.6,193c-0.7,0.7-0.9,3.3-1.2,7
		c-5.7-11.1-8.4-24.5-6.8-36.4l-4.3,9.8c-2-0.7-4.1-2.8-6.4-6.4c0.8,3.9,2.3,7.5,4.6,10.8l-8.5-2.1c-3.2-2.4-4.2-6.4-3-11.8
		l21.3-11.6l8.5,0.6c0.6-0.7,2-3.1,5-3.7l3.2,10.5l4.4,9.5c-2.4,3.4-4.5,7.2-6.4,11.4c-2.2-0.5-3.9-0.8-5-1.5
		c-1.3,0.5-2.1,2.7-0.8,5.1l-1.3,0.2c-1.3,0.9-2,2.2-2,4.3C118.5,189.8,118.5,190.7,118.6,193z M128.3,181.9l-0.9,1.6l-1.6,0.4
		c-1.2-0.7-1.7-1.7-1.4-2.9C126.6,181.8,128.1,182.1,128.3,181.9z M124.8,206l2.3,1.1c-1.6,16.6,2.6,30.9,11.5,36.4
		C128.2,239.2,123,224,124.8,206z M129,208l2.3,1.1c-1.6,16.5,2.7,30.7,11.5,36.2C132.5,241,127.3,225.9,129,208z M133,209.9l2.2,1
		c-1.3,15.9,2.7,29.6,11,34.9C136.5,241.6,131.6,227.1,133,209.9z M146.6,234.5c-3.1-0.7-5.7-2.9-7.5-6.3l20.4,0.1
		C155,233.3,150.4,235.3,146.6,234.5z M138.6,213.5c3,2.4,7.7,4.8,14.2,7.3l-11.3,0.6L138.6,213.5z M186.3,223.6
		c-0.2,1.6-1.1,2.2-2.9,1.9c-20.1,0-22.3-11.1-41.2-16.1c-6.3,0.2-11.7-2.1-16.1-7c-1.7,0.4-3.1,0.4-4.1,0v2.3l-2.8-2.8
		c-0.5-1.6-0.5-2.7,0.1-3.3l6.3-0.3c1-0.4,1.1-0.8,0.2-1.2l-6.2-0.5c-0.3-1.2,0-2.1,0.9-2.6l6.2,0.4c0.9-0.5,1.1-0.8,0.5-1l-6.5-0.5
		c-0.4-1.5,0-2.5,1-2.9h6.8c0.7-0.4,0.8-0.7,0.4-1l-6.7-0.4c-0.5-1.4-0.2-2.4,0.8-3.1l9.7,0.2l14.6,13.3l21.8,3.2
		c9.2,2,16.5,5.1,19.8,10.6C191.6,219.4,189.4,220.6,186.3,223.6z M191.6,204.9c-3.6-4.6-7.6-6.9-11.9-6.8c4.9,1.2,8.5,3.9,10.8,8.2
		c-3.2-3.6-6.3-5.1-9.2-4.6c5,2,8.4,5.2,10.2,9.4c0.6,0.3,0.5,1-0.3,2.2c-7.2-9.6-15.5-14.3-24.9-14.3c-0.6-0.9-0.6-2.2,0.2-3.7
		C176.7,190.2,189.4,192.2,191.6,204.9z M187.4,193.2c-8.4-2.7-15.5-3-21.4-0.8c-3-5.9-3.8-12.7-2.3-20.4c-4.3,5.5-5,12.5-2,21.1
		v5.5l-14-1.6c-2.1-1.7-4.3-3.6-6.4-5.7c7.4-19.4,21.8-32.6,29.8-30.5c1.1,0.3,2.1,0.5,3.1,0.8c6.4,4,9.7,10.9,10.1,20.7
		L187.4,193.2z M203.8,177.1c-3,5.8-6.9,7.2-13.4,3.5c-2-1.1-3.5-3.3-4.5-6c-0.5-3.1-1.2-6-2.3-8.6c-0.6-3.4-1-6.7-1.5-9
		c-3-15,0.6-29.6,3.3-44.3c0,0,0-0.1,0-0.1l6.5,40.4l6.6,2.1l4.8-2.8C204.6,161.4,205.7,173.5,203.8,177.1z M142.6,131.2
		c1.5-0.2,2.7-2.4,3.8-2.1c1.1,0.1,1.8,0,2.1-0.2c0.3,1,0.3,1.8,0.1,2.2l-0.9,0.3l-0.5,0.5c-0.5-0.3-1.3-0.6-2.4-0.7
		C144.3,131.6,143.6,131.6,142.6,131.2z M143.4,135.4l-0.9,0.9c0-0.4,0-0.8,0-1.2h0.8l2.7-1.9l1.4,0.4l0.7-0.7l1.9,0.7l0.5,0.6
		l-1.3-0.4c-1,0.9-2.1,1.3-3.2,0.8L143.4,135.4z M150.4,135.8l0.3,1.5l-0.6-0.6c-1.6,1.1-3.1,1.4-4.3,0.9l-1.2,0.9l0.6-1.5
		C146.7,136.8,148.5,136.4,150.4,135.8z M120.7,163.2c4.2-7.5,8.2-6.2,12.2,3.8C129.4,165.7,127.4,156.1,120.7,163.2z M125.5,111.5
		c7.4-3.1,13.4-0.7,18.1,7.1c-1.2,0.2-3,0.6-5.4,1l1.8-1.4C138.6,112.9,133.7,110.6,125.5,111.5z M123.9,119.7l-0.7,0l4-2.5h1
		l3.2,4.4l-2.3,0.3l-2-3.4L123.9,119.7z"
        />
      </g>
    </svg>
  );
};

EngexLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default EngexLogo;
