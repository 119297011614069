/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ContactUs from 'common/ContactUs';
import EngexFull from 'svg/logos/EngexFull';

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      {isMd ? (
        <Box
          component="img"
          sx={{
            position: 'absolute',
            objectFit: 'cover',
            width: '100%',
            height: 'auto',
            maxHeight: '100%',
          }}
          alt="Aerial Work performed by Engex Tech Inc."
          // eslint-disable-next-line no-undef
          src={require('assets/images/home/banner.png')}
        />
      ) : (
        <></>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            data-aos={isMd ? 'fade-right' : 'fade-up'}
            borderRadius="16px"
            backgroundColor="rgba(256,256,256,0.9)"
            padding={2}
          >
            <EngexFull width={'100%'} height={'auto'} />
            <Box marginBottom={3}>
              <Typography
                variant="h6"
                component="p"
                color="textSecondary"
                sx={{ fontWeight: 400 }}
              >
                <Typography
                  fontWeight={700}
                  component={'span'}
                  variant={'inherit'}
                >
                  ENG
                </Typography>
                ineering{' '}
                <Typography
                  fontWeight={700}
                  component={'span'}
                  variant={'inherit'}
                >
                  EX
                </Typography>
                cellence - Engex Tech Inc is a trusted partner to complete
                telecom network construction and end-user installations.
              </Typography>
            </Box>
            <Box>
              <ContactUs name={'Contact Us'}></ContactUs>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Hero;
