import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import { Form } from './components';
import { useAuth, useAuthentication } from 'auth';
import { useNavigate } from 'react-router-dom';

const LoginSimple = () => {
  const { isTokenValid } = useAuthentication();
  const navigate = useNavigate();
  if (isTokenValid()) {
    navigate('/user/projects');
  }
  return (
    <Box
      position={'relative'}
      minHeight={'calc(100vh - 247px)'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <Container maxWidth={600}>
        <Form />
      </Container>
    </Box>
  );
};

export default LoginSimple;
