import React from 'react';
import Modal from '@mui/material/Modal';

export default function BasicModal({
  isModalOpen,
  handleCloseModal,
  children,
}) {
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {children}
      </Modal>
    </div>
  );
}
