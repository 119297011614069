/* eslint-disable no-undef */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

const Main = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Services
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
          data-aos={'fade-up'}
        >
          Telecom Construction
        </Box>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            media: require('../../../../../assets/images/construction/abstract/aerial.jpeg'),
            title: 'Aerial',
            subtitle:
              'Pole framing, strand installation, lashing cable, attachment transfer, etc.',
            url: '/construction/aerial',
            urlText: 'Learn more about aerial',
          },
          {
            media: require('../../../../../assets/images/construction/abstract/underground.jpeg'),
            title: 'Underground',
            subtitle:
              'Hub installations, flower pots, man holes, hand digging, installing conduits, etc.',
            url: '/construction/underground',
            urlText: 'Learn more about underground',
          },
          {
            media: require('../../../../../assets/images/construction/abstract/splicing.jpeg'),
            title: 'Splicing',
            subtitle:
              'Case preparation, ribbonizing, fusion splicing, testing, etc.',
            url: '/construction/splicing',
            urlText: 'Learn more about splicing',
          },
          {
            media: require('../../../../../assets/images/construction/abstract/anchor.jpeg'),
            title: 'Anchors',
            subtitle: 'Install anchors and connect to down guy',
            url: '/construction/anchors',
            urlText: 'Learn more about anchors',
          },
          {
            media: require('../../../../../assets/images/construction/abstract/pole.jpeg'),
            title: 'Poles',
            subtitle: 'Remove and install new poles',
            url: '/construction/poles',
            urlText: 'Learn more about poles',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box
              component={'a'}
              href={item.url}
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-end'}>
                  <Button size="small" href={item.url}>
                    {item.urlText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginBottom={4} marginTop={5}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        ></Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
          data-aos={'fade-up'}
        >
          Telecom End User Installations
        </Box>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            media: require('../../../../../assets/images/installations/abstract/residential.jpeg'),
            title: 'Residential',
            subtitle:
              'From drop to internet, phone, and cable services installation',
            url: '/telecom-installations/residential',
            urlText: 'Learn more about residential installations',
          },
          {
            media: require('../../../../../assets/images/installations/abstract/commercial.jpeg'),
            title: 'Commercial',
            subtitle:
              'Internet, phone, and cable installation with focus on reliability and security',
            url: '/telecom-installations/commercial',
            urlText: 'Learn more about commercial installations',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box
              component={'a'}
              href={item.url}
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-end'}>
                  <Button size="small" href={item.url}>
                    {item.urlText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginBottom={4} marginTop={5}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        ></Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
          data-aos={'fade-up'}
        >
          Telecom Random Tasks
        </Box>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            media: require('../../../../../assets/images/random_tasks/abstract/aerial_drop_removal.jpeg'),
            title: 'Drop Removal',
            subtitle: 'Test and remove unused drops',
            url: '/about',
            urlText: 'Contact Us',
          },
          {
            media: require('../../../../../assets/images/random_tasks/abstract/site_inspection.jpeg'),
            title: 'Site Inspection',
            subtitle: 'Assess task quality',
            url: '/about',
            urlText: 'Contact Us',
          },
          {
            media: require('../../../../../assets/images/random_tasks/abstract/coax_tap_swap.jpeg'),
            title: 'Coax Tap Swap',
            subtitle: 'Swap tap and test signal quality',
            url: '/about',
            urlText: 'Contact Us',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box
              component={'a'}
              href={item.url}
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-end'}>
                  <Button size="small" href={item.url}>
                    {item.urlText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
