import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { ProjectCard } from './components';
import { useSelector } from 'react-redux';
import { projectsApi } from 'api';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Projects = () => {
  const { token } = useSelector((state) => state).user;
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    projectsApi
      .getProjectsByUsername(token)
      .then((response) => {
        setProjects((currentState) => [...response.data, ...currentState]);
      })
      .catch((error) => {
        alert('There been an issue loading projects, please try again!');
      });
  }, []);

  return (
    <Box marginY={4}>
      <Container>
        <Box marginBottom={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="#">
              Projects
            </Link>
          </Breadcrumbs>
        </Box>
        <Grid container spacing={4}>
          {projects.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <ProjectCard
                id={item.id}
                name={item.name}
                location={item.location}
                image={item.image}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Projects;
