/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from 'common/Container';
import { useParams } from 'react-router-dom';
import { Typography, colors } from '@mui/material';
import { BiPattern, Reviews } from './components';

const Hiring = () => {
  const { id } = useParams();
  const partnerApplyUrl = 'https://forms.office.com/r/6nnSfDef9C';
  const employeeApplyUrl = 'https://forms.office.com/r/i8SCqRkUtv';
  const installation = {
    residential: {
      title: 'Telecom Residential Installations',
      id: 0,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Enhance safety and reliability',
        'Analyze work orders',
        'Excellent customer service',
        'On-site professional expertise',
        'Reporting',
        'Performance monitoring',
        'Fiber, Coax, and Copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/installations/residential/1.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/installations/residential/2.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/installations/residential/3.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
    commercial: {
      title: 'Telecom Commercial Installations',
      id: 1,
      employeeApply: employeeApplyUrl,
      partnerApply: partnerApplyUrl,
      subtitleTwo: 'Descriptors',
      color: colors.blueGrey,
      lessons: [
        'Modern business solutions',
        'Enhance safety and reliability',
        'Analyze work orders',
        'Excellent customer service',
        'On-site professional expertise',
        'Reporting',
        'Performance monitoring',
        'Fiber, Coax, and Copper',
      ],
      photos: [
        {
          src: require('../../../assets/images/installations/commercial/1.jpeg'),
          rows: 1,
          cols: 1,
        },
        {
          src: require('../../../assets/images/installations/commercial/2.jpeg'),
          rows: 1,
          cols: 1,
        },
      ],
    },
  };
  useEffect(() => {
    // Scroll to the element with the specified ID
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [id]);

  return (
    <Box>
      <Container>
        <Box marginBottom={4}>
          <Box
            component={Typography}
            fontWeight={700}
            variant={'h3'}
            gutterBottom
            align={'center'}
          >
            Telecom End User Installation
          </Box>
          <Typography align={'center'} color="textSecondary" variant={'h6'}>
            Install and maintain internet, voice, and TV cable
            <br />
            services for various providers and network types.
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Container
        id="residential"
        backgroundColor={id === 'residential' ? '#C5E9D6' : ''}
      >
        <BiPattern specs={installation.residential} />
      </Container>
      <Divider />
      <Container
        id="commercial"
        backgroundColor={id === 'commercial' ? '#C5E9D6' : ''}
      >
        <BiPattern specs={installation.commercial} />
      </Container>
      <Container id="hiring" backgroundColor={id === 'hiring' ? '#C5E9D6' : ''}>
        <Reviews employeeApplyLink={employeeApplyUrl} />
      </Container>
    </Box>
  );
};

export default Hiring;
