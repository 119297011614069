import { Email } from '@mui/icons-material';
import axios from 'axios';

const api = {
  addProspect: function (prospect) {
    const { fullName, companyName, email, phone, message } = prospect;
    return axios.post('/api/prospects', {
      fullName,
      companyName,
      email,
      phone,
      message,
    });
  },

  getAllProspects: function (token) {
    return axios.get('/api/prospects', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
