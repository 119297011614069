import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
const ContactUs = ({ name }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      href="/about"
      fullWidth={isMd ? false : true}
      className="chat-button"
    >
      {name}
    </Button>
  );
};
ContactUs.propTypes = {
  name: PropTypes.node.isRequired,
};
export default ContactUs;
