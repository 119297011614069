export const pages = [
  {
    title: 'Engex Tech General',
    id: 'supporting-pages',
    pages: [
      { title: 'Home', href: '/' },
      {
        title: 'About',
        href: '/about',
      },
      {
        title: 'Hire us',
        href: '/hire-us',
      },
      {
        title: 'FAQ',
        href: '/faq',
      },
      {
        title: 'Log In',
        href: '/login',
      },
    ],
  },
  {
    title: 'Telecom Construction',
    id: 'telecom-construction',
    pages: [
      {
        title: 'Hiring',
        href: '/construction/hiring',
      },
      { title: 'Aerial', href: '/construction/aerial' },
      { title: 'Underground', href: '/construction/underground' },
      { title: 'Splicing', href: '/construction/splicing' },
      { title: 'Anchors', href: '/construction/anchors' },
      { title: 'Poles', href: '/construction/poles' },
    ],
  },
  {
    title: 'Telecom End User Installations',
    id: 'telecom-installations',
    pages: [
      {
        title: 'Hiring',
        href: '/telecom-installations/hiring',
      },
      {
        title: 'Residential',
        href: '/telecom-installations/residential',
      },
      {
        title: 'Commercial',
        href: '/telecom-installations/commercial',
      },
    ],
  },
];
