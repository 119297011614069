import { LOG_OUT, LOG_IN } from './actions';

import { isTokenExpired, getTokenLS } from 'auth';
const token = getTokenLS();

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    token: isTokenExpired(token) ? null : token,
    roles: [],
    projects: [],
  },
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.user.firstName || '',
          lastName: action.user.lastName || '',
          email: action.user.email || '',
          username: action.user.username || '',
          token: action.user.token || getTokenLS() || null,
          roles: action.user.roles,
          projects: action.user.projects,
        },
      };
    case LOG_OUT:
      return { ...state, user: initialState.user };
    default:
      return state;
  }
};

export default reducers;
