import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

const ProjectCard = ({ id, name, location, image }) => {
  const theme = useTheme();

  return (
    <Box
      component={'a'}
      href={'/user/projects/' + id + '/maps'}
      display={'block'}
      width={'100%'}
      height={'100%'}
      sx={{
        textDecoration: 'none',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: `translateY(-${theme.spacing(1 / 2)})`,
        },
      }}
    >
      <Box
        component={Card}
        width={'100%'}
        height={'100%'}
        borderRadius={3}
        display={'flex'}
        flexDirection={'column'}
      >
        <CardMedia
          image={image}
          title={name}
          sx={{
            height: 240,
          }}
        />
        <Box component={CardContent}>
          <Typography align={'left'} variant={'body'} color="textPrimary">
            {name}
          </Typography>
          <Typography align={'left'} variant={'body2'} color="textSecondary">
            {location}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box component={CardActions} justifyContent={'flex-start'}>
          <Button
            size="large"
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            Access Project
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ProjectCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default ProjectCard;
