/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import WithLayout from 'WithLayout';

// Available layouts
import { Main as MainLayout, Fixed as FixedLayout } from './layouts';

// Landing pages
import {
  Construction,
  Home,
  Hiring,
  TelecomInstallations,
  HireUs,
} from './views/landingPages';

// Supporting pages
import {
  About as AboutView,
  FrequentlyAskedQuestions as FaqView,
  Privacy as PrivacyView,
  NotFound as NotFoundView,
} from './views/supportingPages';

// Authentication pages
import { LoginSimple as LoginSimpleView } from './views/authPages';

// User pages
import {
  Account as AccountView,
  Projects as ProjectsView,
  Maps as MapsView,
  Map as MapView,
} from './views/userPages';

// Manager pages
import {
  Projects as ManageProjectsView,
  Maps as ManageMapsView,
  Users as ManageUsersView,
} from './views/managerPages';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAuthentication } from 'auth';

function RequireAuth({ children, redirectTo }) {
  // @TODO add logic for authorization check (basically each route should check if user of type EMPLOYEE, MANAGER, or ADMIN is allowed in)
  const { username, token } = useSelector((state) => state).user;
  const { isTokenValid, logIn } = useAuthentication();
  React.useEffect(() => {
    if (!username && isTokenValid(token)) {
      logIn(token);
    }
  }, [username, token]);
  return isTokenValid(token) ? children : <Navigate to={redirectTo} />;
}

const Routes = () => {
  return (
    <ReactRoutes>
      <Route
        exact
        path="/"
        element={((matchProps) => (
          <WithLayout {...matchProps} component={Home} layout={MainLayout} />
        ))()}
      />
      <Route
        exact
        path="/about"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={AboutView}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/faq"
        element={((matchProps) => (
          <WithLayout {...matchProps} component={FaqView} layout={MainLayout} />
        ))()}
      />
      <Route
        exact
        path="/hiring"
        element={((matchProps) => (
          <WithLayout {...matchProps} component={Hiring} layout={MainLayout} />
        ))()}
      />
      <Route
        exact
        path="/hire-us"
        element={((matchProps) => (
          <WithLayout {...matchProps} component={HireUs} layout={MainLayout} />
        ))()}
      />
      <Route
        exact
        path="/privacy"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={PrivacyView}
            layout={MainLayout}
          />
        ))()}
      />

      <Route
        exact
        path="/construction/:id"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={Construction}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/construction"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={Construction}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/telecom-installations/:id"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={TelecomInstallations}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/telecom-installations"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={TelecomInstallations}
            layout={MainLayout}
          />
        ))()}
      />

      <Route
        exact
        path="/login"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginSimpleView}
            layout={MainLayout}
          />
        ))()}
      />

      <Route
        exact
        path="/user/account"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={AccountView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/user/projects"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={ProjectsView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/user/projects/:projectId/maps"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={MapsView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/user/projects/:projectId/maps/:mapId"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={MapView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/manage/users"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={ManageUsersView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/manage/projects"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={ManageProjectsView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/manage/projects/:projectId/maps"
        element={((matchProps) => (
          <RequireAuth redirectTo="/">
            <WithLayout
              {...matchProps}
              component={ManageMapsView}
              layout={FixedLayout}
            />
          </RequireAuth>
        ))()}
      />
      <Route
        path="*"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={NotFoundView}
            layout={MainLayout}
          />
        ))()}
      />
    </ReactRoutes>
  );
};

RequireAuth.propTypes = {
  children: PropTypes.any,
  redirectTo: PropTypes.string,
};
export default Routes;
